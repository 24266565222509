import { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'

import * as Survey from './surveyHelpers'
import { SurveyContext } from './surveyContext'
import Api from '../../services/api'
import {
  trackFreebieDownload,
  trackBadCode,
  trackHitMeetingLink,
} from '../../services/metrics'
import { isMobileOnly } from 'react-device-detect'

import {
  SurveyContainer,
  SurveyHeader,
  SocialLinkIcon,
} from '@talkadot/survey-component-library'
import SurveyFooter from './SurveyFooter'
import { SURVEY_HEIGHT_THRESHOLD } from './SurveyParent'

const ThankYouContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  height: '100%',
}))

const CtaContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.base.white,
  width: '80%',
}))

const SocialContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.625rem',
  width: '100%',
  justifyContent: 'center',
  padding: '1rem',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius.xxxs,
}))

const SocialHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.fontWeights.semiBold,
  textAlign: 'left',
}))

const SocialLinkContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  a: {
    textDecoration: 'none',
    paddingLeft: '0.625rem',
  },
}))

const StyledLinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.navText,
}))

const ErrorContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '90%',
  border: `1px solid ${theme.palette.error.semiDark}`,
  textAlign: 'left',
  padding: '1rem',
  borderRadius: theme.shape.borderRadius.xxxs,
  backgroundColor: theme.palette.error.lightest,
  p: {
    color: theme.palette.error.semiDark,
  },
}))

const SurveyComplete = () => {
  const [offerUrl, setOfferUrl] = useState('')
  const [offerError, setOfferError] = useState(false)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const { surveyState, clearSurvey } = useContext(SurveyContext)
  const {
    socialLinks,
    shortlink,
    name,
    showSurveyOfferQuestions,
    bookingLink,
    canShowBookingLink,
    hideFreebieLink,
  } = surveyState.speaker

  const history = useHistory()

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // If the user didnt just fill out a survey,
  // redirect them back to the homepage or something
  useEffect(() => {
    const getThankYouOffer = async () => {
      try {
        const offerCode = Survey.findOfferCode(surveyState)

        // Protect from erroring out
        if (!offerCode) {
          return
        }

        const params = {
          submission: {
            id: surveyState.submissionId,
            shortlink: shortlink,
            offer_code: offerCode.toUpperCase(),
          },
        }

        const res = await Api.getSurveyOfferByCode(params)

        if (!res.errors) {
          setOfferUrl(res)
        } else {
          throw res.errors
        }
      } catch (err) {
        trackBadCode({
          speaker: name,
        })
        // If this is an event planner thank you, don't set offer error
        if (!surveyState.event.id) {
          setOfferError(true)
        }
      }
    }

    if (!surveyState.id) {
      return history.push('/')
    } else {
      if (showSurveyOfferQuestions && !hideFreebieLink) {
        getThankYouOffer()
      }
    }

    return () => {
      clearSurvey()
    }
  }, [])

  const showBookingLink =
    canShowBookingLink && bookingLink && Survey.isLeadQualified(surveyState)

  const handleSurveyOfferDownload = () => {
    trackFreebieDownload({ speaker: name })
    const regex = /^((http|https):\/\/)/
    const url = !regex.test(offerUrl) ? '//' + offerUrl : offerUrl
    return window.open(url, '_blank').focus()
  }

  const handleMeetingLinkClick = () => {
    trackHitMeetingLink({ speaker: name })
    return window.open(bookingLink, '_blank').focus()
  }

  const renderSurveyOfferLink = () => (
    <StyledButton
      variant="contained"
      color={showBookingLink ? 'neutral' : 'primary'}
      onClick={handleSurveyOfferDownload}>
      {surveyState?.eventGroup?.thank_you_button_text
        ? surveyState.eventGroup.thank_you_button_text
        : surveyState?.speaker?.isCeProvider
        ? 'Get Your CE Certificate'
        : 'Get Your Bonus'}
    </StyledButton>
  )

  const renderBookingLink = () => (
    <StyledButton
      variant="contained"
      color="secondary"
      onClick={handleMeetingLinkClick}>
      Book A Meeting
    </StyledButton>
  )

  const renderOfferLinkError = () => (
    <ErrorContainer>
      <Typography variant="body1">
        Sorry, but something went wrong with that bonus code. Please reach out
        to <b>{name}</b> for your bonus!
      </Typography>
    </ErrorContainer>
  )

  const makeSocialLink = (link, handle) => (
    <a href={link} target="_blank" rel="noreferrer">
      <StyledLinkText variant="body1">{handle}</StyledLinkText>
    </a>
  )

  const makeYoutubeLinkedinLink = (handle, type) => {
    if (!handle) return

    const oldBaseUrl =
      type === 'linkedin'
        ? 'https://www.linkedin.com/in/'
        : 'https://www.youtube.com/channel/'

    let href = oldBaseUrl + handle
    let linkText = handle

    if (handle.includes(`${type}.com`)) {
      href = handle

      const regex = /^((http|https):\/\/)/
      href = !regex.test(href) ? '//' + href : href

      linkText = handle
        .split('/')
        ?.filter((item) => item)
        ?.pop()
    }

    return makeSocialLink(href, linkText)
  }

  const renderSocialLinkLink = (socialType, handle) => {
    switch (socialType) {
      case 'linkedin':
        return makeYoutubeLinkedinLink(handle, socialType)
      case 'instagram':
        return makeSocialLink(
          `https://www.instagram.com/${handle}`,
          `@${handle}`
        )
      case 'twitter':
        return makeSocialLink(`https://twitter.com/${handle}`, `@${handle}`)
      case 'youtube':
        return makeYoutubeLinkedinLink(handle, socialType)
      default:
        return <div />
    }
  }

  const renderSocialLinkItem = (key, i) => {
    const value = socialLinks[key]

    return (
      <SocialLinkContainer key={i}>
        <SocialLinkIcon socialType={key} color="neutral" />
        {renderSocialLinkLink(key, value)}
      </SocialLinkContainer>
    )
  }

  const renderSocialLinks = () => (
    <SocialContainer>
      <SocialHeader variant="body2">Let's connect on social</SocialHeader>
      {socialLinks &&
        Object.keys(socialLinks).map((key, i) => {
          return socialLinks[key] && renderSocialLinkItem(key, i)
        })}
    </SocialContainer>
  )

  const renderThankYouText = () => {
    // For a global event planner survey, show custom thank you text
    // If not a global planner survey, show event text
    // If not an event, show normal text

    if (!surveyState.event.id) {
      return 'All Done! Thank you so much! 😁'
    }

    if (
      surveyState.event?.event_type?.toUpperCase()?.includes('GLOBAL') &&
      surveyState?.eventGroup?.thank_you_message
    ) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: surveyState.eventGroup.thank_you_message,
          }}
        />
      )
    }

    return 'Feedback sent!'
  }

  const emptySocials =
    !socialLinks?.instagram &&
    !socialLinks?.twitter &&
    !socialLinks?.linkedin &&
    !socialLinks?.youtube

  const surveyHeight =
    isMobileOnly || screenHeight < SURVEY_HEIGHT_THRESHOLD
      ? '100%'
      : SURVEY_HEIGHT_THRESHOLD

  return (
    <SurveyContainer
      isTestMode={Survey.isTestMode()}
      showBorder={!isMobileOnly}
      height={surveyHeight}>
      <SurveyHeader survey={surveyState} />
      <ThankYouContainer>
        <Typography variant="subtitle1">{renderThankYouText()}</Typography>
        <CtaContainer>
          {showBookingLink && renderBookingLink()}
          {offerUrl && renderSurveyOfferLink()}
        </CtaContainer>
        {offerError && renderOfferLinkError()}

        {/* Only show social links if this is NOT an event planner flow */}
        {!surveyState.event.id && !emptySocials && renderSocialLinks()}
      </ThankYouContainer>
      <SurveyFooter />
    </SurveyContainer>
  )
}

export default SurveyComplete
