import { useContext, useEffect, useState } from 'react'
import { SurveyContext } from './surveyContext'
import { FreeFlowPossibleAnwerTemplate } from '@talkadot/survey-component-library'

const FreeFlowPossibleAnswer = ({ possibleAnswer }) => {
  const [rows, setRows] = useState(8)
  const { updateAnswerValue } = useContext(SurveyContext)

  useEffect(() => {
    const viewPortHeight = window.innerHeight
    const rows = Math.floor(viewPortHeight / 100)
    setRows(rows)
  }, [])

  const handleChange = (e) => {
    updateAnswerValue(possibleAnswer, e.target.value)
  }

  return (
    // Note
    // the subcontent for these questions should go as the placeholder
    // in the textbox
    <FreeFlowPossibleAnwerTemplate
      placeholder={possibleAnswer.subContent}
      handleChange={handleChange}
      answerValue={possibleAnswer.answerValue}
      rows={rows}
    />
  )
}

export default FreeFlowPossibleAnswer
