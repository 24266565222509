import { useContext, useState, useEffect } from 'react'

import { SurveyContext } from './surveyContext'
import * as Survey from './surveyHelpers'
import { isValidEmail } from '../../utils/validator'

import { SimpleTextPossibleAnswerTemplate } from '@talkadot/survey-component-library'

const BLOCKED_CHARACTERS = new Set([
  ' ',
  '(',
  ')',
  '<',
  '>',
  '[',
  '\\',
  ']',
  '`',
  ':',
  ';',
  '"',
  ',',
])

const SimpleTextPossibleAnswer = ({ possibleAnswer }) => {
  const { updateAnswerValue, surveyState } = useContext(SurveyContext)
  const [error, setError] = useState(null)
  const [hasBlurred, setHasBlurred] = useState(false)

  useEffect(() => {
    if (possibleAnswer.answerType === Survey.ANSWER_TYPES.EMAIL) {
      const email = Survey.findEmail(surveyState)
      if (email) {
        updateAnswerValue(possibleAnswer, email)
      }
    }

    if (possibleAnswer.answerType === Survey.ANSWER_TYPES.NAME) {
      const name = Survey.findName(surveyState)
      if (name) {
        updateAnswerValue(possibleAnswer, name)
      }
    }

    // Solve bug where error states were persisting across multiple inputs
    setError(null)
  }, [possibleAnswer.id])

  const { answerType, answerValue, helperText } = possibleAnswer

  const handleChange = (e) => {
    if (invalidKeyPress(e)) {
      e.preventDefault()
      return
    }

    const inputValue = e.target.value

    updateAnswerValue(possibleAnswer, inputValue)

    // If they have have blurred previously validate right away
    if (hasBlurred) {
      setError(null)
      validateValue(inputValue)
    }
  }

  const validateValue = (inputValue) => {
    if (answerType !== 'EMAIL') {
      return
    }

    // Don't validate empty inputs
    if (!inputValue || inputValue === '') {
      return
    }

    const { valid, message } = isValidEmail(inputValue)

    if (valid) {
      return
    }
    setError(message)
  }

  const invalidKeyPress = (event) => {
    if (answerType !== 'EMAIL') {
      return
    }

    const keyPress = event.nativeEvent.data

    if (BLOCKED_CHARACTERS.has(keyPress)) {
      return true
    } else {
      return false
    }
  }

  const handleBlur = () => {
    setHasBlurred(true)
    validateValue(answerValue) // referencing possibleAnswer does not work
  }

  const placeholder = helperText
    ? helperText.toLowerCase()
    : answerType.toLowerCase().split('_').join(' ')

  return (
    <SimpleTextPossibleAnswerTemplate
      fieldValue={answerValue || ''}
      possibleAnswer={possibleAnswer}
      handleChange={handleChange}
      error={error}
      handleBlur={handleBlur}
      placeholder={Survey.capitalizeFirstLetter(placeholder)}
    />
  )
}

export default SimpleTextPossibleAnswer
