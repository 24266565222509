export const ADD_ANSWER = 'ADD_ANSWER'
export const SET_ANSWERS = 'SET_ANSWERS'
export const SET_LEADING_QUESTION = 'SET_LEADING_QUESTION'
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION'
export const SET_PREVIOUS_QUESTION_ID = 'SET_PREVIOUS_QUESTION_ID'
export const SET_SUBMITTED_QUESTION_IDS = 'SET_SUBMITTED_QUESTION_IDS'
export const REMOVE_PREVIOUS_QUESTION_ID = 'REMOVE_PREVIOUS_QUESTION_ID'
export const LOAD_SURVEY = 'LOAD_SURVEY'
export const SET_PERCENT_COMPLETE = 'SET_PERCENT_COMPLETE'
export const SET_CUSTOM_QUESTION = 'SET_CUSTOM_QUESTION'
export const SET_CUSTOM_QUESTION_ACTIVE = 'SET_CUSTOM_QUESTION_ACTIVE'
export const SET_CUSTOM_QUESTION_INACTIVE = 'SET_CUSTOM_QUESTION_INACTIVE'
export const LOAD_EVENT_PLANNER_WELCOME_QUESTION =
  'LOAD_EVENT_PLANNER_WELCOME_QUESTION'
export const CLEAR_SURVEY = 'CLEAR_SURVEY'
export const SET_CONFIGURATION_CODE = 'SET_CONFIGURATION_CODE'
export const SET_ATTENDEE_EMAIL = 'SET_ATTENDEE_EMAIL'
export const SET_ATTENDEE_NAME = 'SET_ATTENDEE_NAME'
export const SET_SPEAKER_DATA = 'SET_SPEAKER_DATA'
export const SET_FIRST_QUESTION_IDENTIFIER = 'SET_FIRST_QUESTION_IDENTIFIER'
