import { useContext, useState, useEffect } from 'react'
import { SurveyContext } from './surveyContext'
import { BooleanPossibleAnswerTemplate } from '@talkadot/survey-component-library'
import { QUESTION_TYPES } from './surveyHelpers'

const BooleanPossibleAnswer = ({ possibleAnswer, answerType }) => {
  const [selected, setSelected] = useState(false)
  const { submitBooleanAnswer } = useContext(SurveyContext)

  useEffect(() => {
    if (possibleAnswer.answerValue) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [possibleAnswer.id])

  const handleClick = () => {
    submitBooleanAnswer(possibleAnswer)
  }

  return (
    <BooleanPossibleAnswerTemplate
      selected={selected}
      handleClick={handleClick}
      content={possibleAnswer.content}
      answerType={answerType}
      isMultipleChoice={answerType === QUESTION_TYPES.MULTIPLE_CHOICE}
    />
  )
}

export default BooleanPossibleAnswer
