import { useContext } from 'react'
import { styled } from '@mui/system'
import { SurveyContext } from './surveyContext'

const LinkPossibleAnswer = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  margin: '1rem 0',
}))

const OptOutLinkPossibleAnswer = ({ possibleAnswer }) => {
  const { submitBooleanAnswer } = useContext(SurveyContext)

  return (
    <LinkPossibleAnswer onClick={() => submitBooleanAnswer(possibleAnswer)}>
      {possibleAnswer.content}
    </LinkPossibleAnswer>
  )
}

export default OptOutLinkPossibleAnswer
